<template>
  <section class="bg content">
    <ul id="galerie">
      <li
        v-for="(post, index) in posts"
        :key="index"
        @click="gotoUrl(post.permalink)"
        :style="index == 24 ? 'display:none' : ''"
      >
        <img
          :src="
            post.media_type == 'VIDEO' ? post.thumbnail_url : post.media_url
          "
          alt=""
        />
        <div class="over">{{ post.caption }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      posts: [],
    };
  },
  mounted() {
    this.getJson();
  },
  methods: {
    async getJson() {
      const response = await axios.get(
        "https://graph.instagram.com/me/media?fields=id,caption,media_url,media_type,permalink,thumbnail_url&access_token=EAARdWYCV99sBOwrclaAth9BZCFLiCo8m8HxVZAZC8cr3dSYBYn4WgWZCszJ1w4M1vi86P9SgMxdP35cscy81kSFtk9UZBTl7LuiP0xisZCWHl1EHDfOGN6PnBrMFZCZBOSQumEpHbuUNz0UwrPr83nZC5nfwXmbHq8ndUpqJj9fhlNGHVtwNu2sr51EAZAz7St0al3aygZC1sAEmAsJXjXkZCfACIeVJagZA7tgpfSxLZBVe5G05ZBamqT7D6Y4a8UWTqOfJCAZD"
      );
      this.posts = response.data.data;
    },
    gotoUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
<style lang="scss">


</style>