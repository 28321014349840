<template>
  <div>
    <section class="bg-white">
      <div class="bg content">
        <div class="intro">
          <p class="intro__title">Expositions</p>
          <p>
            Un espace galerie d’art offre une exposition régulièrement
            renouvelée d’œuvres d’artistes contemporains.
          </p>
          <p>
            Laissez-nous votre email pour recevoir des informations sur les
            prochaines expositions.
          </p>
          <form ref="form" @submit.prevent="sendEmail" class="form">
            <div>
              <input
                type="email"
                v-model="email"
                name="email"
                placeholder="Votre email"
                required
              /><br />
              <span v-if="msg.email" class="error">{{ msg.email }}</span>
            </div>
            <button type="submit">Envoyer</button>
          </form>
        </div>
        <div v-for="(item, index) in expo" :key="index">
          <p v-if="index == 0" class="section-title now">En ce moment</p>
          <p v-else-if="index == 1" class="section-title">2024</p>
          <p v-else-if="index == 5" class="section-title">2023</p>
          <p v-else-if="index == 9" class="section-title">2022</p>
          <p v-else-if="index == 14" class="section-title">2021</p>
          <div class="two-column">
            <div class="image">
              <img :src="item.image" />
            </div>
            <div class="text">
              <h3 class="title">
                <a
                  v-if="item.title_link"
                  :href="item.title_link"
                  target="_blank"
                  >{{ item.title }}
                </a>
                <span v-else>{{ item.title }}</span>
              </h3>
              <p v-show="item.post_multiline" v-html="item.post_multiline"></p>
              <p v-show="item.name_multiline" v-html="item.name_multiline" class="name"></p>
              <p>  
                <a
                  v-if="item.name_link"
                  :href="item.name_link"
                  target="_blank"
                  class="name"
                  >{{ item.name }}</a
                >
                <span class="name" v-else>{{ item.name }}</span>
                <span v-show="item.post" class="post">{{ item.post }}</span>
              </p>
              <div v-show="item.note" class="note">
                {{ item.note }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "Expositions",
  data() {
    return {
      name: "",
      email: "",
      msg: [],
      expo: [
        {
          image: "img/expo/meunier-curtinet.jpg",
          title: "Jean Meunier Curtinet",
          title_link: "https://www.facebook.com/profile.php?id=100011529817588",
          name: "08/01 - 28/02/2025",
          post_multiline: "Héritier des Nabis, les peintres post-impressionnistes<br>Un premier Prix de peinture à l’Ecole des Beaux-arts en 1975<br>L’une de ses œuvres trône à l’hôtel Négresco de Nice",
          note: "Vernissage : le jeudi 16 janvier à partir de 18h",
        },
        {
          image: "img/expo/alain-pagliano.jpg",
          title: "Alain PAGLIANO",  
          name: "10/10 - 20/12/2024"
        },
        {
          image: "img/expo/favrene.jpg",
          title: "Favrène",
          title_link: "https://www.aralya.fr/expositions/favrene/",
          name: "30/08-30/09/2024"
        },
        {
          image: "img/expo/carole-gourrat.jpg",
          title: "Déambulations poétiques",
          name: "Carole Gourrat",
          name_link: "https://carolegourrat.com/"
        },
        {
          image: "img/expo/pascale-charrier-royer.jpg",
          title: "Couleurs de l'infini",
          name: "Pascale Charrier-Royer",
          name_link: "https://www.pascalecharrierroyer.site/"
        },
        {
          image: "img/expo/koba.jpg",
          title: "AU FIL DE L’ART",
          name: "Ariel KOBA",
          name_link: "https://koba.dictionnairedesartistescotes.com/",
        },
        {
          image: "img/expo/envol-des-zephyrs.jpg",
          title: "L’envol des Zéphyrs",
          name: "Christèle Lefay"
        },
        {
          image: "img/expo/bach.jpg",
          title: "Peintographies",
          name: "Dominique BACHMANN @peintographik"
        },
        {
          image: "img/expo/alkaplan.jpg",
          title: "Animérisme, couleur et résonance",
          post: "Membre sociétaire du Salon d'Automne de Paris",
          name: "Alkaplan (Bruno Pezon)",
          name_link: "https://www.i-cac.fr/artiste/alkaplan-bruno-pezon-dit-1.html"
        },
        {
          image: "img/expo/croisee-regards.jpg",
          title: "A la croisée des regards",
          post: "4 artistes lyonnaises",
          name_multiline: "<a href='https://www.facebook.com/Agapie.art/' target='_blank'>Agapie</a><br><a href='https://www.dehoux-grafmeyer.com/' target='_blank'>Danielle-Dehoux-Grafmeyer</a><br><a href='https://www.facebook.com/profile.php/?id=100017976825247' target='_blank'>Thoul</a><br><a href='https://www.pascalecharrierroyer.site/' target='_blank'>Pascale Charrier-Royer</a>",
        },
        {
          image: "img/expo/uraraka.jpg",
          title: "Lustre des pigments japonais",
          post: "Artiste de peinture japonaise",
          name: "Uraraka",
        },
        {
          image: "img/expo/foodisterie.jpg",
          title: "Foodisterie",
          post: "Photographe culinaire",
          name: "Maureen Domprobst",
        },
        {
          image: "img/expo/malfroy.jpg",
          title: "Tableaux en soie",
          post: "Société lyonnaise de la soierie",
          name: "Malfroy",
        },
        {
          image: "img/expo/aborigenes.jpg",
          title: "Rêves aborigènes",
          post: "Peintres aborigènes par coordination",
          name: "Walala Tjapaltjarri, Ken Brenton, Morris Gibson Tjapajarri, Maureen Hudson, Margaret Scobie,…",
        },
        {
          image: "img/expo/artistes-lyonnaises.jpg",
          title: "Eternité",
          post: "3 artistes lyonnaises",
          name: "Camille Guillaud, Miu Shinoda, Aude Marchal",
        },
        {
          image: "img/expo/franksan.jpg",
          title: "« Métamorphoses » in Miami",
          post: "Photographe lyonnais",
          name: "Franksan",
        },
      ],
    };
  },
  watch: {
    email(value) {
      this.validateEmail(value);
    },
  },
  methods: {
    sendEmail() {
      emailjs
        .sendForm(
          "service_y43sdj2",
          "template_cy0q2el",
          this.$refs.form,
          "lr8O4WTK0rPWQEczO"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            console.log("FAILED...", error.text);
          }
        );
      this.email = "";
      this.msg = "";
    },
    validateEmail(value) {
      //eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Veuillez entrer un email valide";
      }
    },
  },
};
</script>
<style lang="scss">
$gray: #bababa;
.form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  input,
  button {
    font-family: "Cormorant Garamond", serif;
    font-size: 1.6rem;
    margin-top: 1rem;
  }
  input {
    width: 20rem;
    height: 4rem;
    padding: 0 1rem;
    border: 1px solid $gray;
    border-radius: 0.5rem;
    margin-right: 1rem;
  }
  button {
    height: 4rem;
    padding: 0 1rem;
    border: 1px solid $gray;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  .error {
    color: red;
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
}
</style>